@import "@/assets/scss/var.scss";
.promo {
    background: #f8f8f8;
    &-widget{
        position: fixed;
        bottom: 15%;
        right: 20px;
        list-style: none;
        margin: 0;
        padding: 0;
        z-index: 2;
        &__main{
            position:static;
            width: 40px;
            height: 40px;
            // order: 1;
            padding: 5px;
            box-sizing: border-box;
            border-radius: 50%;
            cursor: pointer;
            overflow: hidden;
            box-shadow: 2px 2px 6px #999;
            // transition: all 0.5s ease 0s;
            z-index: 200;
            border: 0px;
            font-size: 28px;
            color: initial;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
    
        }
    }
}
